import { defaultThemeCls } from "../styles/themes/default";
import { notesbogThemeCls } from "../styles/themes/notesbog";

import * as platform from "./constants/platform";
import { createFroalaEditor } from "./createFroalaEditor";
import { createNativeEditor } from "./createNativeEditor";
import type {
  gEditorStatic as gEditorStaticType,
  gEditor as gEditorType,
  gEditorOptions as gEditorOptionsType,
} from "./typings/editor";
import FroalaEditor, * as FE from "./vendor/froala_editor";

// include Froala plugins (side-effect)
import "./vendor/jquery/postmessage";
import "./vendor/froala_editor/plugins";
import "./vendor/froala_editor/languages";

// include stylesheets (side-effect)
import "../styles/vendor/froala_editor.css";
import "../styles/vendor/froala_style.css";
import "../styles/vendor/plugins/index.css";
import "../styles";

// immediately begin loading the CSS to make sure it is available as soon as
// possible
const CSS_PROMISE = new Promise(function (resolve) {
  const scriptUrl =
    document.querySelector<HTMLScriptElement>(
      `script[src*="plugins.gyldendal.dk/gEditor/v"]`,
    )?.src ??
    document.querySelector<HTMLScriptElement>(`script[src$="gEditor.js"]`)
      ?.src ??
    "https://plugins.gyldendal.dk/gEditor/v2.js";

  const stylesheetUrl = scriptUrl.replace(/\.js$/, ".css");

  const link = document.createElement("link");
  link.setAttribute("rel", "stylesheet");
  link.setAttribute("type", "text/css");
  link.setAttribute("href", stylesheetUrl);
  link.onload = link.onerror = link.onabort = resolve;
  (document.head ?? document.body).appendChild(link);
});

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    FroalaEditor?: unknown;
    FE?: unknown;

    gEditor?: gEditorStatic;
  }

  type gEditorStatic = gEditorStaticType;
  type gEditor = gEditorType;
  type gEditorOptions = gEditorOptionsType;
}

window.FroalaEditor = FroalaEditor;
window.FE = FE;

window.gEditor = async function (
  selector,
  { disabledPlugins, ...options } = {},
) {
  await CSS_PROMISE;

  if (!disabledPlugins?.includes("table")) {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    require("./vendor/froala_editor/plugins/table");
  }

  const elem = document.querySelector<HTMLElement>(selector);

  if (elem) {
    // Show element once editor has been initialized
    setTimeout(function () {
      elem.style.visibility = "visible";
    }, 1);

    // Add theme setting to the layout
    elem.classList.add("g-editor");
    elem.classList.add(
      options?.theme === "notesbog" ? notesbogThemeCls : defaultThemeCls,
    );
  }

  return platform.IS_MOBILE || options.useNativeEditor
    ? createNativeEditor(elem, options)
    : createFroalaEditor(selector, options);
};
