export type GGBEditorApi = {
  setEditorState(state: { content: string }): void;
  getEditorState(): { latex: string; content: string };
  evalLaTeX(latex: string): void;
  exportImage(
    args: { type: "svg"; transparent: boolean },
    cb: (args: { svg: string }) => void,
  ): void;
  openKeyboard(): void;
  closeKeyboard(): void;
  remove(): void;
};

type GGBWidgetApi = {
  create(node: HTMLElement): {
    getAPI(): Promise<GGBEditorApi>;
  };
};

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    __gyldendal_ggb__?: Record<string, (api: GGBWidgetApi) => void>;
  }
}

const editorUrl =
  "https://www.geogebra.org/apps/5.2.804.0/editor/editor.nocache.mjs";

export function loadGeogebraScript(): Promise<GGBWidgetApi> {
  return new Promise((resolve, reject) => {
    const editorId = `${Date.now()}_${Math.round(Math.random() * 1000)}`;

    window.__gyldendal_ggb__ = window.__gyldendal_ggb__ || {};
    window.__gyldendal_ggb__[editorId] = resolve;

    const script = document.createElement("script");
    script.type = "module";
    script.src =
      "data:text/javascript;charset=utf-8;base64," +
      btoa(
        `import {editor} from "${editorUrl}"; window.__gyldendal_ggb__["${editorId}"](editor);`,
      );

    script.onerror = () => reject(new Error("loadGeogebraScript().onerror"));
    script.onabort = () => reject(new Error("loadGeogebraScript().onabort"));

    (document.head ?? document.body).appendChild(script);
  });
}
