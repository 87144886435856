import * as FE from "../../index";

import { openGeogebraEvaluator } from "./openGeogebraEvaulator";

FE.PLUGINS.geogebra = function geogebra(editor) {
  // Bind event-listener that will allow the user to double-click a
  // formula in order to open it in the editor again
  editor.events.$on(editor.$el, 'dblclick', '.LaTeX-formula', function (e) {
    openGeogebraEvaluator(
      editor,
      e.currentTarget
    );
  });

  return {
    open() {
      openGeogebraEvaluator(editor);
    }
  };
};

FE.DefineIcon('insertGeogebra', {PATH: 'M19.2 7.652h-6.884L10.7 18.387l-1.058-.188-.809.371-4.031-7.25 1.426-.808 3.043 5.027 1.617-10.113.808.187H19.2zm0 0'});

FE.RegisterCommand('insertGeogebra', {
  title: 'Indsæt formel',

  // This command doesn't change anything - simply opens a lightbox
  // overlay - so don't push anything or refresh anything inside the
  // editor...
  focus: false,
  undo: false,
  refreshAfterCallback: false,

  callback: function () {
    this.geogebra.open();
  }
});
